<template>
    <div class="row">
        <div class="col-12">
            <b-card
                no-body
                class="mb-0"
            >
                <div class="m-2">

                    <!-- Table Top -->
                    <b-row>

                        <!-- Per Page -->
                        <b-col
                            cols="12"
                            md="6"
                            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                        >
                        </b-col>

                        <!-- Search -->
                        <b-col
                            cols="12"
                            md="6"
                        >
                            <div class="d-flex align-items-center justify-content-end">
                                <b-form-input
                                    v-model="search"
                                    class="d-inline-block mr-1"
                                    placeholder="Buscar..."
                                    @keyup="searchVisitas"
                                />
                            </div>
                        </b-col>
                    </b-row>

                </div>
                <b-table
                    :stacked="options.stacked"
                    class="position-relative"
                    :items="visitas"
                    striped
                    responsive
                    :fields="tableColumns"
                    :per-page="options.perPageOptions"
                    :current-page="options.currentPage"
                    primary-key="id"
                    show-empty
                    empty-text="No matching records found"
                    id="tabla-clientes"
                >

                    <template #cell(show_details)="row">

                        <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
                        <span class="show-visits"
                              v-model="row.detailsShowing"
                              @click="row.toggleDetails"
                        >
                            <feather-icon icon="ChevronRightIcon" v-if="row.detailsShowing === false"/>
                            <feather-icon icon="ChevronDownIcon" v-if="row.detailsShowing === true"/>
                        </span>
                        <span class="edit-visits"
                              @click="goDetails(row)"
                        >
                            <feather-icon icon="EditIcon" />
                        </span>
                    </template>
                    <template #cell(date_appointment)="row">
                        <span v-if="row.item.date_appointment !== null">{{ formatDate(row.item.date_appointment) }}</span>
                        <span v-else> </span>
                    </template>
                    <template #cell(date_visited)="row">
                        <span v-if="row.item.date_visited !== null">{{ formatDate(row.item.date_visited) }}</span>
                        <span v-else> </span>
                    </template>
                    <!-- full detail on click -->
                    <template #row-details="row">
                        <b-card>
                            <b-row class="mb-2">
                                <b-col
                                    md="4"
                                    class="mb-1"
                                >
                                    <strong>Última visita: </strong>
                                    {{ formatDate(row.item.ultima_visita) }}
                                </b-col>
                                <b-col
                                    md="4"
                                    class="mb-1"
                                >
                                    <strong>Saldo pendiente: </strong>{{ (row.item.saldo !== null) ? row.item.saldo : 0 }} €
                                </b-col>
                            </b-row>

                            <b-button
                                size="sm"
                                variant="outline-primary"
                                @click="row.toggleDetails"
                            >
                                Ocultar
                            </b-button>
                        </b-card>
                    </template>
                </b-table>
            </b-card>
        </div>

    </div>
</template>

<script>

    import { BRow, BCol, BFormInput, BCard, BTable, BFormCheckbox, BButton } from 'bootstrap-vue'
    import useJwt from '@/auth/jwt/useJwt'
    import moment from 'moment'


    export default {
        name: 'Visitas',
        components: {
            BRow,
            BCol,
            BFormInput,
            BCard,
            BTable,
            BFormCheckbox,
            BButton,
            useJwt
        },
        data () {
            return {
                search: '',
                idCustomer: null,
                visitas: [],
                setTimeoutSearch: '',
                refreshRender: true,
                options: {
                    stacked: false,
                    perPageOptions: null,
                    currentPage: 1,
                    rows: null,
                    filter: null,
                },
                tableColumns: [
                    /*{
                        label: this.estado,
                        sortable: false,
                    },*/
                    {
                        label: '',
                        key: 'show_details',
                        sortable: false,
                        class: 'edit-button',
                    },
                    {
                        label: 'Cliente',
                        key: 'nomfactura',
                        sortable: true,
                    },
                    {
                        label: 'Citado',
                        key: 'date_appointment',
                        sortable: true,
                    },
                    {
                        label: 'Visitado',
                        key: 'date_visited',
                        sortable: true,
                    },
                    {
                        label: 'Asunto',
                        key: 'notes',
                        sortable: false,
                    },
                    {
                        label: 'Comercial',
                        key: 'name',
                        sortable: true,
                    },
                ],
            }
        },
        mounted() {
            this.getVisitas()
        },
        methods: {
            getVisitas() {
                const self = this

                useJwt.getVisits({ 'search': self.search })
                    .then((result) => {
                        self.visitas = Object.values(result.data.visits)
                        self.options.perPage = result.data['pagination']
                    })
            },
            searchVisitas () {
                clearTimeout(this.setTimeoutSearch)
                this.setTimeoutSearch = setTimeout(this.getVisitas, 300)
            },
            formatDate(value) {
                return moment(value).format('DD-MM-YYYY')
            },
            goDetails(row) {
                const id = (row.item.fk_customer + "").padStart(5,"0")
                this.$router.push({ name: 'Detalles Cliente', params: { idCustomer: id } })
            },
        },
    }

</script>

<style>
    .edit-button {
        width: 70px;
        padding: 0.72rem 1rem !important;
    }
    .edit-visits {
        float: right;
    }
    .show-visits:hover,
    .edit-visits:hover {
        cursor: pointer;
    }
    sup {
        float:right;
        margin-left:10px;
        margin-top: -5px;
        font-size: 15px;
    }

</style>
